<template>
	<div class="table-container">
		<perfect-scrollbar class="ps--100">
			<div v-if="productData && productData.length > 0" class="table" :class="layout == 'grid' ? 'table--grid': 'table--list'">
				<div class="table-header">
					<div class="table-header__col table-header__col--first" v-if="layout == 'grid'">
						<!-- <a @click="setSort('created_at')" href="#">Nejnovější <i class="icon icon--short"></i></a> -->
						<div class="dropdown">
							<a v-click-away="hideDropDown" @click="toggleDropDown">{{$t('products.order_by')}} <i class="icon icon--short"></i></a>
							<transition name="fade">
								<div class="dropdown__menu" v-if="showSort">
									<ul>
										<li><a @click="setSort('created_at_sort')">{{$t('products.upload_date')}} <i class="icon icon--short" :class="sort == 'created_at_sort' ? sortDirection: ''"></i></a></li>
										<li><a @click="setSort('name')">{{$t('products.title')}} <i class="icon icon--short" :class="sort == 'name' ? sortDirection: ''"></i></a></li>
										<li><a @click="setSort('ean')">EAN <i class="icon icon--short" :class="sort == 'ean' ? sortDirection: ''"></i></a></li>
										<li><a @click="setSort('sap_id')">SAP ID <i class="icon icon--short" :class="sort == 'sap_id' ? sortDirection: ''"></i></a></li>
									</ul>
								</div>
							</transition>
						</div>
					</div>
					<div class="table-header__col table-header__col--first" v-if="layout == 'list'">
						<div class="from__item form__item--checkbox">
							<input type="checkbox" name="product_all" @click="selectAll" v-model="allSelected">
							<span></span>
						</div>
					</div>
					<div class="table-header__col table-header__col--img display-sm-none" v-if="layout == 'list'"></div>
					<div class="table-header__col table-header__col--title" v-if="layout == 'list'">
						<a @click="setSort('created_at')"  href="#" class="display-lg-none">{{$t('products.latest')}} <i class="icon icon--short"></i></a>
						<a @click="setSort('name')" href="#" class="display-sm-none">{{$t('products.title')}} <i class="icon icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center display-sm-none" v-if="layout == 'list'">
						<a @click="setSort('created_at_sort')" href="#">{{$t('products.latest')}} <i class="icon icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center display-sm-none" v-if="layout == 'list'">

						<a @click="setSort('ean')" href="#">EAN <i class="icon icon--short"></i></a>
					</div>
					<div class="table-header__col table-header__col--center display-sm-none" v-if="layout == 'list'">
						<a @click="setSort('sap_id')" href="#">SAP ID <i class="icon icon--short"></i></a>
					</div>
					<div class="table-header__col" v-if="layout == 'list'"></div>
					<div class="table-header__col table-header__col--control table-header__col--right">
						<div class="form__item form__item--checkbox flex align-items--center" v-if="layout == 'grid' && choiceVisible">
							<input type="checkbox" name="product_all" id="product_all" @click="selectAll" v-model="allSelected">
							<span></span>
							<label for="product_all"> {{$t('products.select_all')}}</label>
						</div>
						<div class="form__item form__item--checkbox-label flex align-items--center" v-if="layout == 'grid'">
							<input type="checkbox" name="choice" id="choice" @click="choice">
							<label for="choice">{{$t('products.selection')}}</label>
						</div>
						<div class="btn-switcher">
							<button class="btn btn--grid" :class="{'active': layout == 'grid'}" @click="layout = 'grid'">
								<svg class="icon icon--switch icon--grid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><g transform="translate(-1285 -102)"><g transform="translate(1293 110)" fill="none" stroke="#d0d0d0" stroke-width="2"><rect width="6" height="6" stroke="none"/><rect x="1" y="1" width="4" height="4" fill="none"/></g><g transform="translate(1293 102)" fill="none" stroke="#d0d0d0" stroke-width="2"><rect width="6" height="6" stroke="none"/><rect x="1" y="1" width="4" height="4" fill="none"/></g><g transform="translate(1285 110)" fill="none" stroke="#d0d0d0" stroke-width="2"><rect width="6" height="6" stroke="none"/><rect x="1" y="1" width="4" height="4" fill="none"/></g><g transform="translate(1285 102)" fill="none" stroke="#d0d0d0" stroke-width="2"><rect width="6" height="6" stroke="none"/><rect x="1" y="1" width="4" height="4" fill="none"/></g></g></svg>
							</button>
							<button class="btn btn--list" :class="{'active': layout == 'list'}" @click="layout = 'list'">
								<svg class="icon icon--switch icon--list" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12"><g transform="translate(-1308 -103.303)"><line x1="10" transform="translate(1312 104.303)" fill="none" stroke="#d0d0d0" stroke-width="2"/><line x1="2" transform="translate(1308 104.303)" fill="none" stroke="#d0d0d0" stroke-width="2"/><line x1="10" transform="translate(1312 109.303)" fill="none" stroke="#d0d0d0" stroke-width="2"/><line x1="2" transform="translate(1308 109.303)" fill="none" stroke="#d0d0d0" stroke-width="2"/><line x1="10" transform="translate(1312 114.303)" fill="none" stroke="#d0d0d0" stroke-width="2"/><line x1="2" transform="translate(1308 114.303)" fill="none" stroke="#d0d0d0" stroke-width="2"/></g></svg>
							</button>
						</div>
					</div>
				</div>
				<div v-if="layout == 'grid'" class="grid">
					<div class="card"  v-for="(item, index) in productData" :key="index" :class="item.type == 'video' || item.type == 'recipe' || item.type == 'photo' ? 'card--full-img' : ''" @contextmenu.prevent="openContextMenu($event, item, index)" @click="openProductModal(item)">
						<div class="card__img">
							<img loading="lazy" v-if="item.thumbnail" :src="item.thumbnail" :alt="item.title" :title="item.title" widht="315" height="240">
							<div class="card__label">
								<span v-for="version in item.versions" :key="version" class="label label--card">{{ version }}</span>
							</div>
						</div>
						<div class="card__row">
							<div class="card__col card__col--checkbox" v-show="choiceVisible">
								<div class="form__item form__item--checkbox">
									<input type="checkbox" :value="item.id" v-model="productIds" @click.stop>
									<span></span>
								</div>
							</div>
							<div class="card__col card__col--text">
								<div class="card__title"><strong>{{ item.name }}</strong></div>
								<div class="card__info"><span>{{ item.size }}</span> <span>{{ item.created_at_formatted }}</span></div>
							</div>
							<div class="card__col card__col--edit">
								<button class="btn btn--edit" @click.stop="openContextMenu($event, item, index)"><span class="icon icon--edit"><span></span><span></span><span></span></span></button>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="table__row" v-for="(item, index) in productData" :key="index" @contextmenu.prevent="openContextMenu($event, item, index)" @click="openProductModal(item)">
					<div @click.stop="triggerInput(item)" class="table__col table__col--first">
						<div class="form__item form__item--checkbox">
							<input type="checkbox" ref="inputCheckbox" :value="item.id" v-model="productIds" @click.stop>
							<span></span>
						</div>
					</div>
					<div class="table__col table__col--img">
						<img loading="lazy" v-if="item.thumbnail" :src="item.thumbnail" :alt="item.title" :title="item.title" width="64" height="42">
					</div>
					<div class="table__col table__col--title">
						<strong>{{ item.name }}</strong>
						<span class="display-lg-none">{{ item.created }}</span>
					</div>
					<div class="table__col table__col--center display-sm-none">{{ item.created_at_formatted }}</div>
					<div class="table__col table__col--center display-sm-none">{{ item.ean || '-' }}</div>
					<div class="table__col table__col--center display-sm-none">{{ item.sap_id }}</div>
					<div class="table__col table__col--center display-sm-none">
						<div>
							<span v-for="version in item.versions" :key="version" class="label label--list">{{ version }}</span>
						</div>
					</div>
					<div class="table__col table__col--edit table__col--right">
						<button class="btn btn--edit" @click.stop="openContextMenu($event, item, index)"><span class="icon icon--edit"><span></span><span></span><span></span></span></button>
					</div>
				</div>
				<ContextMenu ref="menu">
					<ul>
						<li v-if="false"><a href=""><i class="icon icon--share"></i> {{$t('products.share')}}</a></li>
						<li v-if="isAdmin"><a @click.prevent="openProductModal(contextMenuItem)" href=""><i class="icon icon--pen-white"></i> {{$t('products.rename')}}</a></li>
					</ul>
					<ul>
						<li>
							<a @click.prevent="downloadAll(contextMenuItem.id)" href="">
								<i class="icon icon--download-white"></i> {{$t('products.download_all')}}
								<span v-if="downloading==='all'+contextMenuItem.id" class="spinning-loader"></span>
								<span v-else class="label label--products">ZIP</span>
							</a>
						</li>
						<li v-if="type !== 'video'">
							<a @click.prevent="openView"><i class="icon icon--download-white"></i> {{$t('products.other_formats')}} <i clas="icon icon--arrow"></i></a>
							<div v-show="showContextMenuView">
								<ul>
									<li v-for="(type, index) in types" :key="index">
										<a @click.prevent="downloadFileType(contextMenuItem.id, type.key)" href="">
											<i class="icon icon--download-white"></i>{{ type.type }}
											<span v-if="downloading===type.key+contextMenuItem.id" class="spinning-loader"></span>
											<span v-else class="label label--products">{{ type.format }}</span>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
					<ul v-if="isAdmin">
						<li><a @click.prevent="openDeletePopup(contextMenuItem.id)"><i class="icon icon--trash-white"></i> {{$t('products.delete')}}</a></li>
					</ul>
				</ContextMenu>

				<teleport to="#modals">
					<WarningPopup :open="deleteId !== null" @delete-item="deleteProduct" @close-modal="deleteId = null"></WarningPopup>
				</teleport>
			</div>
			<div v-else class="wrapper">
				<div class="dash-card dash-card--profile flex flex-row--sm flex-row--md justify-content--start justify-content-md--start align-items--center">
					{{$t('products.no_products_found')}}
				</div>
			</div>
		</perfect-scrollbar>
		<div v-if="productIds.length > 0" class="table__download">
			<button @click.stop="cancelPanel" class="btn btn--cancel"><strong class="btn__text btn__text--red">{{$t('products.cancel')}}</strong></button>
			<button class="btn btn--download-table" @click.stop="downloadExport">
				<span class="btn__text">
					<strong>{{$t('products.export_selected_copy')}}</strong>
				</span>
				<span v-if="downloading==='export'" class="spinning-loader spinning-loader--white"></span>
				<i v-else class="icon icon--download-white"></i>
			</button>

			<button class="btn btn--download-table" @click.stop="downloadSelection">
				<span class="btn__text">
					<strong v-if="type === 'video'">{{$t('products.export_selected_videos')}}</strong>
					<strong v-else>{{$t('products.export_selected_photos')}}</strong>
				</span>
				<span v-if="downloading==='selection'" class="spinning-loader spinning-loader--white"></span>
				<i v-else class="icon icon--download-white"></i>
			</button>
		</div>
<!--		<UploadDropTarget class="drop-container&#45;&#45;table">-->
<!--			<div class="table__upload">-->
<!--				<button class="button-upload-round" title="Nahrát Soubory"></button>-->
<!--				<strong>Přetáhněte sem</strong>-->
<!--			</div>-->
<!--		</UploadDropTarget>-->
		<ProductModal :type="type" v-if="modalOpen" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { isAdmin, hasPermission, isInRole } from "@/store/auth";

import {
	state as modalState,
	openProductModal, closeModal,
} from '@/store/modal'

import {
	state as brandsState,
} from '@/store/brands'

import {
	state as productsState,
	loadProductData,
	downloadFileType,
	downloadAll,
	removeProduct,
	downloadExport,
	downloadSelection,
} from '@/store/products'

import UploadDropTarget from '@/components/Upload/UploadDropTarget.vue'
import ContextMenu from '@/components/ContextMenu/ContextMenu.vue'
import ProductModal from '@/views/Products/ProductModal.vue'
import {ProductData, ProductFileType} from "@/types";
import {availableProductTypes} from "@/types/product";
import WarningPopup from "@/components/Popup/WarningPopup.vue";
import {state as appState} from "@/store/app"
import {updateCurrentUploadType} from "@/store/upload";
import {UploadType} from "@/enums";
import router from "@/router";

export default defineComponent({
    name: 'ProductList',
	components: {
		WarningPopup,
		ContextMenu,
		ProductModal
	},
	props: {
		type: {
			type: String,
			required: true,
		},
		archive: {
			type: Boolean,
			required: false,
			default: false
		},
		id: {
			type: Number,
			default: null,
			required: false
		},
	},
    data () {
        return {
            layout: 'grid',
			choiceVisible: false,
            allSelected: false,
            productIds: [],
			modalItem: null,
			showContextMenuView: false,
			panelVisible: false,
			contextMenuItem: null,
			contextMenuIndex: null,
			downloading: "",
			deleteId: null,
			sort: "created_at_sort",
			sortDirection: "desc",
			showSort: false,
        }
    },

	mounted() {
		if (!isAdmin.value && ["video", "recipe", "photo", "private_cz", "private_sk"].includes(this.type) ) {
			router.push('/')
		}

    	if (this.id) {
			let item = this.productData.find((item) => item.id === this.id)
			if (item) {
				//@ts-ignore
				openProductModal(item)
			}
		}

		/** @ts-ignore **/
		updateCurrentUploadType(this.type);
	},

	created() {
		loadProductData()
	},

	computed: {
		productData() {
			const type:string = this.type
			/** @ts-ignore **/
			const archive:boolean = this.archive

			let result = productsState.data.filter((item) => item.type === type && item.archived === archive)

			if (appState.searchTags.length > 0) {
				result = result.filter((item) => item.tags.some(tag => appState.searchTags.includes(tag)))
			}

			const searchTerm = (appState.search || appState.persistentSearch).toLocaleLowerCase()


			if (searchTerm.length < 1 && ![UploadType.PRIVATE_CZ.toString(), UploadType.PRIVATE_SK.toString()].includes(type)) {
				result = result.filter((item) => item.brand_id === brandsState.currentId)
			}

			if (['video', 'recipe', 'photo'].includes(type)) {
				if (searchTerm) {
					const searchIds = productsState.data.filter((item) =>
						(item.ean?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
						|| (item.sap_id?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0).map((item) => item.id)

					result = result.filter((item) =>
						(item.ids.some(itm => searchIds.includes(itm)))
						|| (item.name?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase().search(searchTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "")) ?? -1) >= 0
					)
				}
			} else {
				if (searchTerm) {
					result = result.filter((item) =>
						(item.ean?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
						|| (item.sap_id?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
						|| (item.name?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase().search(searchTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "")) ?? -1) >= 0
					)
				}
			}

			result.sort((one, two) => {
				if (this.sortDirection === 'asc') {
					if (one[this.sort] > two[this.sort]) {
						return 1
					} else {
						return -1
					}
				} else {
					if (one[this.sort] < two[this.sort]) {
						return 1
					} else {
						return -1
					}
				}
			})

			return result
		},

		isAdmin(): boolean {
			return !! isAdmin.value
		},

		modalOpen(): boolean { return modalState.open },
		types() {
			const response: ProductFileType[] = []

			if (this.contextMenuItem === null) {
				return response;
			}

			for (let availableType of availableProductTypes) {
				// @ts-ignore: Object is possibly 'null'
				if (this.contextMenuItem[availableType.key.toString()]) {
					response.push(availableType)
				}
			}

			return response
		},
		currentType(): string {
			closeModal()
			return this.type
		},

	},

	watch: {
    	currentType(newVal, oldVal) {},
		productIds(newVal, oldVal) {
    		if (newVal.length != this.productData.length) {
    			this.allSelected = false
			} else if (newVal.length === this.productData.length) {
				this.allSelected = true
			}
		},
		type(newVal) {
			updateCurrentUploadType(newVal)
		}
	},

    methods: {
		openProductModal,

		setSort(field) {
			if (this.sort === field) {
				this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
			} else {
				this.sortDirection = 'asc'
			}
			this.sort = field
		},

		triggerInput (input) {
			if (this.productIds.length < 1) {
				this.productIds = [];
			}
			// @ts-ignore
			if (this.productIds.includes(input.id)) {
				// @ts-ignore
				this.productIds.splice(this.productIds.indexOf(input.id))
			} else {
				// @ts-ignore
				this.productIds.push(input.id)
			}

		},
        selectAll () {
            if (this.allSelected) {
				this.productIds = [];
			} else {
				// @ts-ignore
				this.productData.forEach(item => {this.productIds.push(item.id)})
                // this.productlist.forEach(item => {
                //     // this.productIds.push(item.id)
                // });
            }
        },
		choice () {
            this.choiceVisible = !this.choiceVisible
			if ( !this.choiceVisible ) {
				this.allSelected = false
				this.productIds = []
			}
        },
		panelShow () {
			this.panelVisible = true
		},
		cancelPanel () {
			this.panelVisible = false
			this.allSelected = false
			this.productIds = []
		},

		openContextMenu(e, item, index) {
			this.contextMenuItem = item
			this.contextMenuIndex = index
			const menu: any = this.$refs.menu;
			menu.openMenu(e)
		},
		openView (e) {
			e.preventDefault()
			this.showContextMenuView = !this.showContextMenuView
		},
		openDeletePopup(id) {
			this.deleteId = id
		},
		deleteProduct () {
			if (removeProduct(this.deleteId)) {
				this.deleteId = null
			}
		},
		async downloadAll(id) {
			this.downloading = 'all'+id;
			await downloadAll(id)
			this.downloading = '';
		},

		async downloadExport() {
			this.downloading = 'export';
			await downloadExport(this.type, this.productIds)
			this.downloading = '';
		},

		async downloadSelection() {
			this.downloading = 'selection';
			await downloadSelection(this.productIds)
			this.downloading = '';
		},

		async downloadFileType(id, type: string) {
			this.downloading = type+id;
			await downloadFileType(id, type);
			this.downloading = '';
		},

		toggleDropDown () {
			this.showSort = !this.showSort
		},
		hideDropDown () {
			this.showSort = false
		},

    }
})
</script>


<template>
    <modal :open="modalOpen" @close="close">
        <div @click.stop class="modal modal--item">
			<button class="modal-close-button" @click="close"><i class="icon icon--cross"> {{$t('products.close')}}</i></button>
			<perfect-scrollbar class="ps--100">
				<div class="modal-control" style="">
					<button class="button-simple" @click="close"><i class="icon icon--arrow-back"></i> {{$t('products.back')}}</button>
					<div class="modal-control__nav">
						<button @click="activeTab = 1" :class='{"active": activeTab === 1}' class="btn-modal">{{ typeLabel }}</button>
						<button v-if="textTabs?.[0]" @click="activeTab = 2" :class='{"active": activeTab === 2}' class="btn-modal">{{ textTabs[0] }}</button>
						<button v-if="textTabs?.[1]" @click="activeTab = 3" :class='{"active": activeTab === 3}' class="btn-modal">{{ textTabs[1] }}</button>
					</div>

					<label v-if="isAdmin" style="margin-left: auto; margin-right: 7.375em" class="label label-toggle" for="night-mode">
						<span>{{$t('products.archive')}}</span>
						<div class="input-toggle">
							<input class="input-checkbox" id="night-mode" type="checkbox" @click="unfreeze" :checked="data.archived" v-model="data.archived">
							<span class="input-toggle-handle"></span>
						</div>
					</label>
				</div>
				<div>
					<div v-if="activeTab === 1 && data.type !== 'video'" class="modal-container">
						<div class="modal-header flex flex-column flex-row-md justify-content-md--space-between">
							<div class="w-100 w-48-md">
								<div class="row">
									<DefaultInput :disabled="!isAdmin" v-model="data.name" />
								</div>
								<div class="m-1-5-0-0-0">
									<div class="m-1-0-0-0">
										<SingleSelect
											:disabled="!isAdmin"
											:options="brandData"
											v-model="data.brand_id"
											:placeholder="$t('modal.select_brand')"
										/>
									</div>
									<div class="m-1-0-0-0">
										<TagSelect :label-prop="getLabelProp" :disabled="!isAdmin" :showLabel="false" v-model="data.tags" :options="getFilteredTags" />
									</div>
								</div>
								<div class="m-1-5-0-0-0">
									<div class="download-image">
										<div v-for="(type, index) in types" :key="index" class="download-image__col">
											<div v-if="data[type.key]">
												<button @click="downloadFileType(type.key)" class="btn btn--download">
													<span class="btn__text">
														<strong>Master {{ type.format }}</strong>
														<span>{{ type.colorspace }} {{data[type.key]}} </span>
													</span>
													<span v-if="downloading===type.key" class="spinning-loader"></span>
													<i v-else class="icon icon--download-red"></i>
												</button>
												<div class="download-image__row">
													<button v-if="isAdmin" @click="removeFileType(type.key)" class="btn-simple btn-simple--icon-left">
														<i class="icon icon--trash-gray"></i>
														<span class="btn-simple__text btn-simple__text--size-11 btn-simple__text--color-gray">{{$t('products.delete')}}</span>
													</button>
												</div>
											</div>
										</div>
										<UploadProductChangeDropTarget v-if="isAdmin" :item="data">
											<template v-slot:default>
												<button class="btn btn--upload">
													<span class="btn__text btn__text--gray">
														<strong>{{$t('products.drag_to_upload')}}</strong>
													</span>
													<span class="icon icon--plus">+</span>
												</button>
											</template>
											<template v-slot:uploading>
												<button class="btn btn--upload">
													<span class="btn__text btn__text--gray">
														<strong>{{$t('products.loading')}}</strong>
														<span class="spinning-loader"></span>
													</span>
												</button>
											</template>
										</UploadProductChangeDropTarget>
									</div>
								</div>
							</div>
							<div class="w-100 w-48-md">
								<div class="img">
									<div class="img__container" @click="overlayPopup = true">
										<img @click="overlayPopup = true" :src="data.thumbnail" :alt="data.name">
									</div>
									<div v-if="pairProducts">
										<br>
										<div class="image-list__header">
											<h3>{{$t('products.assigned_products')}}</h3>
										</div>
										<br>
										<div class="form__item form__item form__item--id"><label>EAN: </label>
										<Multiselect
											v-model="data.ids"
											mode="tags"
											:searchable="true"
											:valueProp="'id'"
											:label="'ean'"
											:trackBy="'ean'"
											:disabled="!isAdmin"
											:options="productSelectDatasource"
											:placeholder="$t('products.add_ean')"
										>
											<template v-slot:noresults>
												<span class="multiselect-noresult">{{$t('products.no_products_to_assign')}}</span>
											</template>
										</Multiselect>
										</div>
										<br>
										<div class="form__item form__item form__item--id"><label>SAP ID: </label>
										<Multiselect
											v-model="data.ids"
											:disabled="!isAdmin"
											mode="tags"
											:searchable="true"
											:valueProp="'id'"
											:label="'sap_id'"
											:trackBy="'sap_id'"
											:options="productSelectDatasource"
											:placeholder="$t('products.add_sap_id')"
										>
											<template v-slot:noresults>
												<span class="multiselect-noresult">{{$t('products.no_products_to_assign')}}</span>
											</template>
										</Multiselect>
										</div>
									</div>
									<div v-else class="img__info">
										<div class="col col--1">
											<div class="form__item form__item form__item--id">
												<label>EAN: </label>
												<input :disabled="!isAdmin" placeholder="______" type="text" v-model="data.ean">
											</div>
										</div>
										<div class="col col--2">
											<div class="form__item form__item--id">
												<label for="">SAP ID: </label>
												<input :disabled="!isAdmin" placeholder="______" type="text" v-model="data.sap_id">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-body">
							<ImageList :downloading="downloading" @download-type="downloadType" @download-all="downloadAll" />
						</div>
					</div>
					<div v-else-if="activeTab === 1 && data.type === 'video'" class="modal-container">
						<div class="modal-header">
							<div class="row">
								<DefaultInput :disabled="!isAdmin" v-model="data.name"/>
							</div>
						</div>
						<div class="modal-body">
							<div class="flex flex-column flex-row-md justify-content-md--space-between">
								<div class="row w-100 w-48-md">
									<SingleSelect :disabled="!isAdmin" :options="brandData" v-model="data.brand_id"/>
								</div>
								<div class="row w-100 w-48-md m-1-5-0-0-0 m-md-0">
									<TagSelect :disabled="!isAdmin" :showLabel="false" v-model="data.tags" :options="getFilteredTags"/>
								</div>
							</div>
							<div v-if="pairProducts" class="m-1-5-0-0-0">
								<div class="row">
									<span class="">{{$t('products.assigned_products')}}</span>
								</div>
								<div class="flex flex-column flex-row-md justify-content-md--space-between m-0-5-0-0-0">
									<div class="w-100 w-48-md">
										<Multiselect
											v-model="data.ids"
											mode="tags"
											:searchable="true"
											:valueProp="'id'"
											:label="'ean'"
											:trackBy="'ean'"
											:options="productSelectDatasource"
											placeholder="Přidat EAN"
											:disabled="!isAdmin"
										>
											<template v-slot:noresults>
												<span class="multiselect-noresult">{{$t('products.no_products_to_assign')}}</span>
											</template>
										</Multiselect>
									</div>
									<div class="w-100 w-48-md m-1-5-0-0-0 m-md-0">
										<Multiselect
											v-model="data.ids"
											mode="tags"
											:searchable="true"
											:valueProp="'id'"
											:label="'sap_id'"
											:trackBy="'sap_id'"
											:disabled="!isAdmin"
											:options="productSelectDatasource"
											placeholder="Přidat SAP ID"
										>
											<template v-slot:noresults>
												<span class="multiselect-noresult">{{$t('products.no_products_to_assign')}}</span>
											</template>
										</Multiselect>
									</div>
								</div>
							</div>
							<div class="m-2-0-0-0">
								<a @click.stop="toggleVideo" class="modal-video" @mouseover="videoHover = true" @mouseout="videoHover = false">
									<img @click.stop="toggleVideo" :src="data.thumbnail" :alt="data.name" width="648" height="436">
									<video @ended="videoStatus = false" v-show="videoStatus === true" @click.stop="toggleVideo" ref="videoPlayer" width="648" height="436">
										<source
											:src="data.video_url"
											type="video/mp4"
										/>
									</video>
									<i class="icon icon--play" v-show="!videoStatus" @click.stop="toggleVideo"></i>
									<i class="icon icon--pause" v-show="videoStatus && videoHover" @click.stop="toggleVideo"></i>
								</a>
							</div>
						</div>

						<div class="modal-footer flex justify-content--end">
							<a @click="downloadFileType('video_master', data)" href="#" class="btn-simple btn-simple--with-icon">
								<button class="btn btn--download btn--download-video"><span class="btn__text"><strong>Stáhnout</strong><span>MP4 {{data['video_master']}}</span></span><i class="icon icon--download-red"></i></button>
								<!-- <span class="btn-simple__text btn-simple__text--museo-700 btn-simple__text--size-12 btn-simple__text--color-red btn-simple__text--underline">STÁHNOUT</span> -->
								<span v-if="downloading==='video_master'" class="spinning-loader"></span>
							</a>
						</div>
					</div>
					<div v-if="activeTab === 2" class="modal-container">
						<QuillEditor :readOnly="!isAdmin" v-if="ready" :contentType='"html"' v-model:content="data.content_marketing" theme="snow" :options="isAdmin ? quillOptions : quillOptionsDisabled"/>
					</div>
					<div v-if="activeTab === 3" class="modal-container">
						<QuillEditor :readOnly="!isAdmin" v-if="ready" :contentType='"html"' v-model:content="data.content_legal" theme="snow" :options="isAdmin ? quillOptions : quillOptionsDisabled" />
					</div>
				</div>

				<div class="modal-container modal-container--pt-0">
					<div class="modal-footer flex flex-column flex-row-md justify-content--space-between align-items--baseline">
						<div class="modal-footer__created">
							<div v-for="(item, index) in originalData.changelog" :key="index">
								<span>{{ item.action_human }} {{ item.created_at_formatted }} –</span> <strong>{{ item.user_name }}</strong>
							</div>
						</div>
						<div v-if="isAdmin" class="modal-footer__button flex flex-wrap align-items--center justify-content--end m-1-0-0-0">
							<div class="col m-0-1-0-0">
								<button class="btn-simple" @click="discardChanges">
									<span class="btn-simple__text btn-simple__text--museo-700 btn-simple__text--size-12 btn-simple__text--color-gray btn-simple__text--underline">{{$t('products.discard_changes')}}</span>
								</button>
							</div>
							<div class="col">
								<button class="btn btn--red btn--radius-5 btn--shadow btn--p-1-2 btn--with-icon" @click="saveChanges">
									<span class="btn__text btn__text--font-size-13 btn__text--white">{{$t('products.save_changes')}}</span>
									<span v-if="saving" class="spinning-loader spinning-loader--white"></span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</perfect-scrollbar>
			<teleport to="#modals">
				<OverlayPopup v-if="overlayPopup" :open="overlayPopup" @close-modal="overlayPopup = false" class="modal-mask--z-index" :popupClass="'modal--img'">
					<img @click="overlayPopup = false" :src="data.full_image" :alt="data.name">
				</OverlayPopup>
			</teleport>
		</div>
    </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import {$t, getLanguage} from '@/config'
import { QuillEditor } from '@vueup/vue-quill'
import Modal from '@/components/Modal/Modal.vue'
import ImageList from '@/components/Products/ImageList.vue'
import { getFilteredTags } from '@/store/tags';
import DefaultInput from '@/components/Input/DefaultInput.vue'
import SingleSelect from '@/components/Select/SingleSelect.vue'
import OverlayPopup from '@/components/Popup/OverlayPopup.vue'
import TagSelect from '@/components/Select/TagSelect.vue'
import UploadProductChangeDropTarget from '@/components/Upload/UploadProductChangeDropTarget.vue'
import { availableProductTypes } from "@/types/product";
import { state as modalState, closeModal } from "@/store/modal"
import { state as appState } from "@/store/app"
import { getFilteredBrands, state as brandState } from "@/store/brands"
import {
	state as productsState,
	saveData,
	removeFileType,
	downloadFileType,
	downloadAll,
	loadProductData, downloadType, state as productState,

} from "@/store/products"

import {ModalType} from '@/enums'
import {ProductData, ProductFileType} from "@/types"
import {isAdmin} from "@/store/auth"
import 'quill-paste-smart';

export default defineComponent({
	name: 'ProductModal',
	props: {
		'type': {
			type: String,
			required: true,
		},
		'modalItem': {
			type: Object,
			required: false,
			default: null,
		},
	},
	components: { Modal, ImageList, DefaultInput, SingleSelect, TagSelect, UploadProductChangeDropTarget, QuillEditor, OverlayPopup },

	data(): {
		itemIndex: number,
		data: ProductData,
		downloading: string,
		saving: boolean,
		activeTab: number,
		ready: boolean,
		videoStatus: boolean,
		videoHover: boolean,
		overlayPopup: boolean,
		quillOptions: any,
		quillOptionsDisabled: any,
	} {
		return {
			data: {
				id: null,
				ids: [],
				type: '',
				brand_id: null,
				sap_id: null,
				name: "",
				ean: "",
				description: "",
				tags: [],
				psd_master_rgb: null,
				psd_master_cmyk: null,
				png_master_rgb: null,
				png_master_cmyk: null,
				jpeg_master_rgb: null,
				jpeg_master_cmyk: null,
				content_legal: "",
				content_marketing: "",
				video_master: null,
				thumbnail: '',
				full_image: '',
				changelog: [],
				deleted: false,
				archived: false,
				frozen: false,
			},
			downloading: '',
			saving: false,
			itemIndex: 0,
			activeTab: 1,
			ready: false,
			videoStatus: false,
			videoHover: false,
			overlayPopup: false,
			quillOptions: {
				modules: {
					clipboard: {
						allowed: {
							tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
							attributes: ['href', 'rel', 'target', 'class']
						},
						keepSelection: true,
						substituteBlockElements: true,
						magicPasteLinks: true,
						hooks: {
							uponSanitizeElement(node, data, config) {
								console.log(node);
							},
						},
					}
				}
			},
			quillOptionsDisabled: {
				modules: {
					"toolbar": false
				}
			}
		}
	},

	computed: {
		getLabelProp() { return 'name_' + appState.language },
		getFilteredTags() { return getFilteredTags },
		originalData() { return modalState.data },
    	modalOpen() { return modalState.activeType === ModalType.ProductDetail },
		types() {
    		const response: ProductFileType[] = []
    		for (let availableType of availableProductTypes) {
				if (this.data[availableType.key.toString()]) {
					response.push(availableType)
				}
			}
    		return response
		},
		brandData() { return getFilteredBrands },
		isAdmin():boolean { return isAdmin.value },
		canEdit():boolean { return ! isAdmin.value },
		textTabs(): string[] {
			return $t('products.tabs')[this.type] ?? $t('products.default_tabs');
		},
		typeLabel(item):string { return $t('type.' + item.type) },
		pairProducts():boolean {
			return !["product", "private_sk", "private_cz"].includes(this.type)
		},
		productSelectDatasource() {
			return productState.data.filter(
				(product) => product.brand_id === brandState.currentId && product.archived !== true && (product.sap_id || product.ean)
			)
		},
	},

	mounted () {
		this.updateFromStore()
		this.ready = true
	},
	methods: {
		$t,
		updateFromStore() {
			Object.assign(this.data, modalState.data)
		},
		show () {
			// openProductModal(modalState.data.id)
		},
		close () {
			closeModal()
		},
		discardChanges() {
			Object.assign(this.data, modalState.data)
		},
		async saveChanges() {
			this.saving = true
			await saveData(modalState.data, this.data)
			await loadProductData()
			this.saving = false
		},
		removeFileType(type: string) {
			if (removeFileType(modalState.data, this.data.id, type)) {
				this.data[type] = null
			}
		},
		async downloadFileType(type: string) {
			this.downloading = type;
			await downloadFileType(this.data.id, type)
			this.downloading = '';
		},
		async downloadType(type: string) {
			this.downloading = type;
			await downloadType(this.data.id, type)
			this.downloading = '';
		},
		async downloadAll() {
			this.downloading = 'all';
			await downloadAll(this.data.id)
			this.downloading = '';
		},
		toggleVideo() {
			if (this.videoStatus) {
				/** @ts-ignore */
				this.$refs.videoPlayer.pause();
			} else {
				/** @ts-ignore */
				this.$refs.videoPlayer.play();
			}
			this.videoStatus = !this.videoStatus;
		},
		play() {
			/** @ts-ignore */
			this.$refs.videoPlayer.play();
		},
		pause() {
			/** @ts-ignore */
			this.$refs.videoPlayer.pause();
		},
		unfreeze($e: Event) {
			if (this.data.frozen) {
				if (confirm($t('products.remove_from_archive_prompt'))) {
					this.data.frozen = false
					this.data.archived = false
				} else {
					$e.preventDefault()
				}
			}
		}

	}
})
</script>

<style lang="scss">
.ql-editor{
	min-height:250px;
}

.label-toggle {
	border: 0 !important;
	& > span {
		font-size: 1.4em;
		position: relative;
		bottom: -0.3em;
		padding-right: 10px;
	}
}

.input-toggle {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	&:before {
		 content: "";
		 left: 0;
		 color: #fff;
	}

	&:after {
		 content: "";
		 right: 0;
		 color: #C10C1E;
	}

	&:before,
	&:after {
		 position: absolute;
		 top: 50%;
		 transform: translateY(-50%);
		 padding: 12px 16px;
		 font-size: 12px;
		 z-index: 0;
	}

	input {
		appearance: none;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
	}

	input:checked ~ .input-toggle-handle {
		box-shadow:
			inset 0 1px rgba(black, .15),
			inset 0 0 0 1px #C10C1E;

		&:before {
			 left: calc(50% + 3px);
			 box-shadow:
				 inset 0 -1px rgba(black, .2),
				 inset 0 0 0 1px #C10C1E,
				 0 1px 2px rgba(black, .1),
				 0 6px 12px rgba(black, .1);
		}
	}
}

.input-toggle-handle {
	display: block;
	width: 54px;
	height: 30px;
	background-image: linear-gradient(#C10C1E, #C10C1E);
	border-radius: 10em;
	transition: .4s ease;
	box-shadow:
		inset 0 1px rgba(black, .15),
		inset 0 0 0 1px rgba(black, .15),
		inset 0 1px 2px rgba(black, .15),
		inset 54px 0px #999;
}

.input-toggle-handle:before {
	content: "";
	position: absolute;
	z-index: 1;
	top: 6px;
	left: 8px;
	width: 18px;
	height: 18px;
	transition: .4s ease;
	background: #fff;
	border-radius: 10em;
	box-shadow:
		inset 0 -1px rgba(black, .2),
		inset 0 0 0 1px rgba(black, .15),
		0 1px 2px rgba(black, .1),
		0 6px 12px rgba(black, .1);
}



</style>


import { defineComponent } from 'vue'

import {
	openProductModal,
} from '@/store/modal'

import {
	downloadBulkExport,
} from '@/store/products'

export default defineComponent({
    name: 'BulkDownload',
	components: {
	},
    data () {
        return {
			downloading: false,
            productIds: "",
			colorspace: "rgb",
			format: 'png',
        }
    },

	mounted() {
	},

	created() {
	},

    methods: {
		openProductModal,
		async downloadBulk() {
			this.downloading = true;
			const productIds = this.productIds.split(/[,; \r?\n]/).map((item) => item.trim())
			await downloadBulkExport(productIds, {colorspace: this.colorspace, format: this.format })
			this.downloading = false;
		},
    }
})

import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/views/Auth/Login.vue'
// import Sign from '@/views/Auth/Sign.vue'
import ForgottenPassword from '@/views/Auth/ForgottenPassword.vue'
import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import ProductList from "@/views/Products/ProductList.vue";
import UserList from "@/views/Users/UserList.vue";
import UserProfile from "@/views/Users/UserProfile.vue";
import UserAdd from "@/views/Users/UserAdd.vue";
import TagList from "@/views/Tags/TagList.vue";
import NotFound from "@/views/Error/Error.vue";
import PermissionDenied from "@/views/Error/403.vue";
import ProductsUpload from "@/views/Products/ProductsUpload.vue"

import {redirectToLoginIfNotAuthenticated, redirectToHomeIfAuthenticated, errorIfNotAdmin} from "@/router/routerHelpers";
import TagAdd from "@/views/Tags/TagAdd.vue";
import BulkDownload from "@/views/Products/BulkDownload.vue";

const routes = [
	{
		path: '/',
		beforeEnter: redirectToLoginIfNotAuthenticated,
		redirect: "/dashboard",
	},
	{
		path: '/login',
		beforeEnter: redirectToHomeIfAuthenticated,
		name: 'Login',
		component: Login
	},
	{
		name: 'ForgottenPassword',
		path: '/forgotten-password/:uid/:token/:type',
		beforeEnter: redirectToHomeIfAuthenticated,
		component: ForgottenPassword,
		props: ({ params }) => ({ uid: params.uid, token: params.token, type: params.type }),
	},
	{
		path: '/dashboard',
		component: DashboardLayout,
		beforeEnter: redirectToLoginIfNotAuthenticated,
		children: [
			{
				path: '/dashboard',
				component: Dashboard,
				meta: { allowExternal: true }
			},
			{
				path: '/product',
				component: ProductList,
				props: { type: 'product' },
				meta: { allowExternal: true }
			},
			{
				path: '/product_sk',
				component: ProductList,
				props: { type: 'product_sk' },
				meta: { allowExternal: true }
			},
			{
				path: '/product_hu',
				component: ProductList,
				props: { type: 'product_hu' },
			},

			{
				path: '/private_cz',
				component: ProductList,
				props: { type: 'private_cz' },
			},

			{
				path: '/private_sk',
				component: ProductList,
				props: { type: 'private_sk' },
			},

			{
				path: '/product/archive',
				component: ProductList,
				props: { type: 'product', archive: true },
				beforeEnter: redirectToLoginIfNotAuthenticated,
			},
			{
				path: '/product_sk/detail/:id',
				component: ProductList,
				props: ({params}) => ({type: 'product_sk', id: Number.parseInt(params.id, 10) || 0}),
			},
			{
				path: '/product_hu/detail/:id',
				component: ProductList,
				props: ({params}) => ({type: 'product_hu', id: Number.parseInt(params.id, 10) || 0}),
			},

			{
				path: '/private_cz/detail/:id',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({type: 'private_cz', id: Number.parseInt(params.id, 10) || 0}),
			},
			{
				path: '/private_sk/detail/:id',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({type: 'private_sk', id: Number.parseInt(params.id, 10) || 0}),
			},

			{
				path: '/product/detail/:id',
				component: ProductList,
				props: ({params}) => ({type: 'product', id: Number.parseInt(params.id, 10) || 0}),
			},
			{
				path: '/product/upload',
				component: ProductsUpload,
				beforeEnter: errorIfNotAdmin,
			},
			{
				name: 'Videos',
				path: '/video',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: { type: 'video' },
			},
			{
				path: '/video/detail/:id',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({type: 'video', id: Number.parseInt(params.id, 10) || 0})
			},
			{
				name: 'Recipes',
				path: '/recipe',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: { type: 'recipe' },
			},
			{
				path: '/recipe/detail/:id',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({type: 'recipe', id: Number.parseInt(params.id, 10) || 0})
			},
			{
				name: 'Photos',
				path: '/photo',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: { type: 'photo' },
			},
			{
				path: '/photo/detail/:id',
				component: ProductList,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({type: 'photo', id: Number.parseInt(params.id, 10) || 0})
			},
			{
				name: 'UserList',
				path: '/user',
				beforeEnter: errorIfNotAdmin,
				component: UserList
			},
			{
				name: 'UserAdd',
				path: '/user/add',
				component: UserAdd,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({id: 0})
			},
			{
				name: 'UserProfile',
				path: '/user/profile',
				component: UserProfile,
				props: ({params}) => ({id:  Number.parseInt(params.id, 10) || 0})
			},
			{
				name: 'UserEdit',
				path: '/user/:id',
				component: UserProfile,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({id: Number.parseInt(params.id, 10) || 0})
			},
			{
				name: 'TagList',
				path: '/tag',
				beforeEnter: errorIfNotAdmin,
				component: TagList
			},
			{
				name: 'TagAdd',
				path: '/tag/add',
				component: TagAdd,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({id: 0})
			},
			{
				name: 'TagEdit',
				path: '/tag/edit/:id',
				component: TagAdd,
				beforeEnter: errorIfNotAdmin,
				props: ({params}) => ({id: Number.parseInt(params.id, 10) || 0})
			},
			{
				name: 'BulkDownload',
				path: '/bulk-download',
				component: BulkDownload,
			},
		]
	},

	{ path: '/403', name: 'permission-denied', component: PermissionDenied },
	{ path: '/401', name: 'permission-denied', component: PermissionDenied },

	{ path: '/:pathMatch(.*)', name: 'not-found', component: NotFound },
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router

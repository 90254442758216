<template>
    <modal class="modal-mask--fixed modal-mask--z-index modal-mask--white modal-mask--search modal-hide" :open="modalOpen" @close="close">
		<div class="modal modal--search">
			<button class="modal-close-button" @click="close"><i class="icon icon--cross-black">{{$t('modal.close')}}</i></button>
			<div class="modal-container">
				<div class="modal-body">
					<SearchBar />
				</div>
			</div>
		</div>
    </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from '@/components/Modal/Modal.vue'
import SearchBar from '@/components/Nav/SearchBar.vue';

export default defineComponent({
	name: 'SearchModal',
	components: { Modal, SearchBar },
	data: () => ({
		modalOpen: false
	}),
	methods: {
		open () {
			this.modalOpen = true
		},
		close () {
			this.modalOpen = false
		},
	}
})
</script>

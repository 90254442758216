
import { defineComponent } from 'vue'

import { isAdmin, hasPermission, isInRole } from "@/store/auth";

import {
	state as modalState,
	openProductModal, closeModal,
} from '@/store/modal'

import {
	state as brandsState,
} from '@/store/brands'

import {
	state as productsState,
	loadProductData,
	downloadFileType,
	downloadAll,
	removeProduct,
	downloadExport,
	downloadSelection,
} from '@/store/products'

import UploadDropTarget from '@/components/Upload/UploadDropTarget.vue'
import ContextMenu from '@/components/ContextMenu/ContextMenu.vue'
import ProductModal from '@/views/Products/ProductModal.vue'
import {ProductData, ProductFileType} from "@/types";
import {availableProductTypes} from "@/types/product";
import WarningPopup from "@/components/Popup/WarningPopup.vue";
import {state as appState} from "@/store/app"
import {updateCurrentUploadType} from "@/store/upload";
import {UploadType} from "@/enums";
import router from "@/router";

export default defineComponent({
    name: 'ProductList',
	components: {
		WarningPopup,
		ContextMenu,
		ProductModal
	},
	props: {
		type: {
			type: String,
			required: true,
		},
		archive: {
			type: Boolean,
			required: false,
			default: false
		},
		id: {
			type: Number,
			default: null,
			required: false
		},
	},
    data () {
        return {
            layout: 'grid',
			choiceVisible: false,
            allSelected: false,
            productIds: [],
			modalItem: null,
			showContextMenuView: false,
			panelVisible: false,
			contextMenuItem: null,
			contextMenuIndex: null,
			downloading: "",
			deleteId: null,
			sort: "created_at_sort",
			sortDirection: "desc",
			showSort: false,
        }
    },

	mounted() {
		if (!isAdmin.value && ["video", "recipe", "photo", "private_cz", "private_sk"].includes(this.type) ) {
			router.push('/')
		}

    	if (this.id) {
			let item = this.productData.find((item) => item.id === this.id)
			if (item) {
				//@ts-ignore
				openProductModal(item)
			}
		}

		/** @ts-ignore **/
		updateCurrentUploadType(this.type);
	},

	created() {
		loadProductData()
	},

	computed: {
		productData() {
			const type:string = this.type
			/** @ts-ignore **/
			const archive:boolean = this.archive

			let result = productsState.data.filter((item) => item.type === type && item.archived === archive)

			if (appState.searchTags.length > 0) {
				result = result.filter((item) => item.tags.some(tag => appState.searchTags.includes(tag)))
			}

			const searchTerm = (appState.search || appState.persistentSearch).toLocaleLowerCase()


			if (searchTerm.length < 1 && ![UploadType.PRIVATE_CZ.toString(), UploadType.PRIVATE_SK.toString()].includes(type)) {
				result = result.filter((item) => item.brand_id === brandsState.currentId)
			}

			if (['video', 'recipe', 'photo'].includes(type)) {
				if (searchTerm) {
					const searchIds = productsState.data.filter((item) =>
						(item.ean?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
						|| (item.sap_id?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0).map((item) => item.id)

					result = result.filter((item) =>
						(item.ids.some(itm => searchIds.includes(itm)))
						|| (item.name?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase().search(searchTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "")) ?? -1) >= 0
					)
				}
			} else {
				if (searchTerm) {
					result = result.filter((item) =>
						(item.ean?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
						|| (item.sap_id?.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) ?? -1) >= 0
						|| (item.name?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase().search(searchTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "")) ?? -1) >= 0
					)
				}
			}

			result.sort((one, two) => {
				if (this.sortDirection === 'asc') {
					if (one[this.sort] > two[this.sort]) {
						return 1
					} else {
						return -1
					}
				} else {
					if (one[this.sort] < two[this.sort]) {
						return 1
					} else {
						return -1
					}
				}
			})

			return result
		},

		isAdmin(): boolean {
			return !! isAdmin.value
		},

		modalOpen(): boolean { return modalState.open },
		types() {
			const response: ProductFileType[] = []

			if (this.contextMenuItem === null) {
				return response;
			}

			for (let availableType of availableProductTypes) {
				// @ts-ignore: Object is possibly 'null'
				if (this.contextMenuItem[availableType.key.toString()]) {
					response.push(availableType)
				}
			}

			return response
		},
		currentType(): string {
			closeModal()
			return this.type
		},

	},

	watch: {
    	currentType(newVal, oldVal) {},
		productIds(newVal, oldVal) {
    		if (newVal.length != this.productData.length) {
    			this.allSelected = false
			} else if (newVal.length === this.productData.length) {
				this.allSelected = true
			}
		},
		type(newVal) {
			updateCurrentUploadType(newVal)
		}
	},

    methods: {
		openProductModal,

		setSort(field) {
			if (this.sort === field) {
				this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
			} else {
				this.sortDirection = 'asc'
			}
			this.sort = field
		},

		triggerInput (input) {
			if (this.productIds.length < 1) {
				this.productIds = [];
			}
			// @ts-ignore
			if (this.productIds.includes(input.id)) {
				// @ts-ignore
				this.productIds.splice(this.productIds.indexOf(input.id))
			} else {
				// @ts-ignore
				this.productIds.push(input.id)
			}

		},
        selectAll () {
            if (this.allSelected) {
				this.productIds = [];
			} else {
				// @ts-ignore
				this.productData.forEach(item => {this.productIds.push(item.id)})
                // this.productlist.forEach(item => {
                //     // this.productIds.push(item.id)
                // });
            }
        },
		choice () {
            this.choiceVisible = !this.choiceVisible
			if ( !this.choiceVisible ) {
				this.allSelected = false
				this.productIds = []
			}
        },
		panelShow () {
			this.panelVisible = true
		},
		cancelPanel () {
			this.panelVisible = false
			this.allSelected = false
			this.productIds = []
		},

		openContextMenu(e, item, index) {
			this.contextMenuItem = item
			this.contextMenuIndex = index
			const menu: any = this.$refs.menu;
			menu.openMenu(e)
		},
		openView (e) {
			e.preventDefault()
			this.showContextMenuView = !this.showContextMenuView
		},
		openDeletePopup(id) {
			this.deleteId = id
		},
		deleteProduct () {
			if (removeProduct(this.deleteId)) {
				this.deleteId = null
			}
		},
		async downloadAll(id) {
			this.downloading = 'all'+id;
			await downloadAll(id)
			this.downloading = '';
		},

		async downloadExport() {
			this.downloading = 'export';
			await downloadExport(this.type, this.productIds)
			this.downloading = '';
		},

		async downloadSelection() {
			this.downloading = 'selection';
			await downloadSelection(this.productIds)
			this.downloading = '';
		},

		async downloadFileType(id, type: string) {
			this.downloading = type+id;
			await downloadFileType(id, type);
			this.downloading = '';
		},

		toggleDropDown () {
			this.showSort = !this.showSort
		},
		hideDropDown () {
			this.showSort = false
		},

    }
})

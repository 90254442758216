import {$t, getLanguage} from "@/config";

interface State {
	language: string;
	loading: boolean;
	appData: object;
	error: string|null;
	search: string;
	persistentSearch: string;
	searchTags: number[];
	searchbarItems: any[]
}

const state: State = {
	language: getLanguage(),
	loading: true,
	error: null,
	search: "",
	persistentSearch: "",
	searchTags: [],
	appData: {},
	searchbarItems: [
		{
			label: 'nav.products',
			route: '/product',
			placeholder: $t('searchbar.products'),
			icon_class: '--files',
		},
		{
			label: 'nav.products_sk',
			route: '/product_sk',
			placeholder: $t('searchbar.products_sk'),
			icon_class: '--files',
		},
		{
			label: 'nav.products_hu',
			route: '/product_hu',
			placeholder: $t('searchbar.products_hu'),
			icon_class: '--files',
		},
		{
			label: 'nav.archive',
			route: '/product/archive',
			placeholder: $t('searchbar.archive'),
			icon_class: '--files',
		},
		{
			label: 'nav.videos',
			route: '/video',
			placeholder: $t('searchbar.videos'),
			icon_class: '--files',
		},
		{
			label: 'nav.recipes',
			route: '/recipe',
			placeholder: $t('searchbar.recipes'),
			icon_class: '--files',
		},
		{
			label: 'nav.photos',
			route: '/photo',
			placeholder: $t('searchbar.photos'),
			icon_class: '--files',
		},
		{
			label: 'nav.private_cz',
			route: '/private_cz',
			placeholder: $t('searchbar.private_cz'),
			icon_class: '--files',
		},
		{
			label: 'nav.private_sk',
			route: '/private_sk',
			placeholder: $t('searchbar.private_sk'),
			icon_class: '--files',
		},
		{
			label: 'modal.tags',
			route: '/tag',
			placeholder: $t('searchbar.tags'),
			icon_class: '--files',
		},
		{
			label: 'modal.users',
			route: '/user',
			placeholder: $t('searchbar.users'),
			icon_class: '--files',
		},
	]
}

export default state

<template>
	<div class="table-container">
		<perfect-scrollbar class="ps--100">
			<div class="wrapper">
				<div class="form-profile__header flex align-items--center justify-content--space-between">
					<h2>{{$t('products.bulk_download')}}</h2>
				</div>

				<div class="flex flex-column flex-row-xl m-2-5-0">
					<div class="flex-col--7 p-xl-0-1-25-0-0">
						<div class="m-0-0-2-5-0">
							<div class="dash-card dash-card--profile">

								<p v-html="$t('products.bulk_description')"></p>

								<p style="line-height: 1.4em;">
									<strong style="display: inline-block; width: 11em;">{{$t('products.prefer_colorspace')}}:</strong>
									<span style="display: inline-block; width: 5em;"><input v-model="colorspace" value="rgb" type="radio"> RGB</span>
									<span style="display: inline-block; width: 5em;"><input v-model="colorspace" value="cmyk" type="radio"> CMYK</span>
									<br />
									<strong style="display: inline-block; width: 11em;">{{$t('products.prefer_format')}}:</strong>
									<span style="display: inline-block; width: 5em;"><input v-model="format" value="png" type="radio"> PNG</span>
									<span style="display: inline-block; width: 5em;"><input v-model="format" value="jpeg" type="radio"> JPEG</span>
								</p>
								<br/>
								<p>

								</p>


								<textarea class="styled" v-model="productIds" placeholder="SAP_1, SAP_2, SAP_3"></textarea>
								<br />
								<button class="btn btn--red btn--radius-5 btn--shadow btn--p-1-2 btn--with-icon" @click="downloadBulk">
									<span class="btn__text btn__text--font-size-13 btn__text--white">{{$t('products.download_all')}}</span>
									<span v-if="downloading" class="spinning-loader spinning-loader--white"></span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</perfect-scrollbar>
	</div>
</template>


<script lang="ts">
import { defineComponent } from 'vue'

import {
	openProductModal,
} from '@/store/modal'

import {
	downloadBulkExport,
} from '@/store/products'

export default defineComponent({
    name: 'BulkDownload',
	components: {
	},
    data () {
        return {
			downloading: false,
            productIds: "",
			colorspace: "rgb",
			format: 'png',
        }
    },

	mounted() {
	},

	created() {
	},

    methods: {
		openProductModal,
		async downloadBulk() {
			this.downloading = true;
			const productIds = this.productIds.split(/[,; \r?\n]/).map((item) => item.trim())
			await downloadBulkExport(productIds, {colorspace: this.colorspace, format: this.format })
			this.downloading = false;
		},
    }
})
</script>


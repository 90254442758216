export enum ModalType {
	ProductDetail = 'ProductDetail',
	VideoDetail = 'VideoDetail',
	LegislativeDetail = 'LegislativeDetail',
	RecipeDetail = 'RecipeDetai',
	MarketingDetail = 'MarketingDetail',
	TagDetail = 'Tags',
	BrandSwitch = 'BrandSwitch',
  }

export enum UploadType {
	PRODUCT = 'product',
	PRODUCT_SK = 'product_sk',
	PRODUCT_HU = 'product_hu',
	VIDEO = 'video',
	RECIPE = 'recipe',
	PHOTO = 'photo',
	PRIVATE_SK = 'private_sk',
	PRIVATE_CZ = 'private_cz',
}


export enum RoleEnum {
	USER = 0,
	ADMIN = 2,
	EXTERNAL_AGENCY = 3
}

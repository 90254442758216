import {UploadType} from "@/enums";

interface State {
	currentUploadType: UploadType,
	uploadData: File[],
	previewData: any[],
}

const state: State = {
	currentUploadType: UploadType.PRODUCT,
	uploadData: [],
	previewData: [],
}

export default state

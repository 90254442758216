<template>
    <div class="user-bar user-bar--mobile">
        <div class="dropdown">
            <button @click="toggleDropDown">
                <span class="dropdown__avatar">
                    <img :src="userData.avatar" :alt="userData.name">
                </span>
				<span class="dropdown__heading">
                    <strong>{{ userData.name }}</strong>
                    <span>{{ userData.company }}</span>
                </span>
				<span class="burger">
					<span></span>
					<span></span>
					<span></span>
				</span>
            </button>
            <transition name="fade">
                <div class="dropdown__menu" v-if="show">
                    <ul>
                        <li><router-link to="/user/profile"><i class="icon icon--user-bar icon--myacc"></i> <span>{{$t('user.my_account')}}</span></router-link></li>
                        <li><a @click="handleLogout"><i class="icon icon--user-bar icon--logout"></i> <span>{{$t('user.logout')}}</span></a></li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue"
import { logout, userData } from "@/store/auth"
import {isAdmin} from "@/store/auth";

export default defineComponent({
    name: 'UserBar',
	data () {
		return {
			show: false,
			showSettings: false,
		}
	},
	computed: {
    	userData() { return userData.value },
		isAdmin() { return isAdmin.value },
	},
	methods: {
		toggleDropDown () {
			this.show = !this.show
		},
		hideDropDown () {
			this.show = false
		},

		toggleSettingsDropDown () {
			this.showSettings = !this.showSettings
		},
		hideSettingsDropDown () {
			this.showSettings = false
		},

		handleLogout() {
			logout()
		}
	}
})
</script>